import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

import SEO from '../components/SEO';

import { withQuoteWizard } from '../components/withQuoteWizard';

const styles = theme =>
  createStyles({
    textBox: {
      minHeight: 400,
      maxWidth: 600,
      margin: 'auto',
      padding: '40px 20px',
    },
  });

const IndexPage = ({ classes, children }) => (
  <React.Fragment>
    <SEO
      title="404 - Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `404`,
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />
    <Grid container direction="column" justify="space-around" className={classes.textBox}>
      <Typography variant="h1">404 - Page That Doesn't Exist Found</Typography>
      <Typography variant="body1">
        Technically you found page that doesn't exist (paradox, right) and the
        company producing the best lawns Australia has to offer.
      </Typography>
      <Typography variant="body1">
        Use menu (top right "burger" icon) to learn about us and give us a call
        if you have wish to learn more about which turf might be right for
        your garden, sportsfield or for your in-laws.
      </Typography>
    </Grid>
    {children}
  </React.Fragment>
);

export default withQuoteWizard(withStyles(styles, { withTheme: true })(IndexPage));
